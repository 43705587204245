
    import { Component, Vue, Watch } from "vue-property-decorator"
    import { Caxios } from "./../../../utils/axios";
    import AliOssUpload from '@/utils/oss'
    @Component({
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            branchschoolCode: {
                type: String,
                default: ''
            },
            orderItemId: {
                type: String,
                default: ''
            }
        }
    })
    export default class PModal extends Vue {
        public codeValue: any = ''
        public imgsrc: any = ''
        public AccountNumber: any = ''
        branchschoolCode!: any;
        orderItemId!: any;
        visible!: any
        private fileList: any = []
        private previewVisible = false
        private previewImage = ''
        private fileImg = ''
        private handlePreview (file: any): void {
            this.previewImage = file.url || file.thumbUrl
            this.previewVisible = true
        }
        private handleCancel (): void {
          this.previewVisible = false
        }
        private handleChange (info: any): void {
          let fileList = [...info.fileList];

          // 限制上传文件数量
          fileList = fileList.slice(-1);

          // 处理上传状态
          fileList = fileList.map((file) => {
            if (file.response) {
              file.url = file.response.url; // 假设返回的响应中有 url 字段
            }
            return file;
          });

          this.fileList = fileList;
        }
        private customRequest (options: any): void {
            console.log(options, '上传的内容')
          const { file, onSuccess, onError, onProgress } = options;
          AliOssUpload.upload(file).then(res => {
            if (res) {
              console.log('url', res)
              this.fileImg = res
              onSuccess(null, res);
            }
          });
        }
        @Watch('visible')
        visibleChange (newVal: any, oldVal: any) {
            this.getPayCode()
        }
        created() {
            if (this.visible) {
                this.getPayCode()
            }
        }
      private handleOk(): void {
        this.$emit('registSuc')
      }
        private async goPay() {
            if (!this.fileImg) {
                this.$message.error('Please upload your payment screenshot!')
                return
            }
          const res: any = await Caxios.post({ url: '/api/official/order/goToReview ' , data: {
              file: this.fileImg,
              id: this.orderItemId
            }})
          if(res.code!== '10000') return
            this.$emit('payOk')
        }
        private cancel(): void {
          this.fileImg = ''
          this.$emit('cancel')
        }
        public copyEvent (id: string): void {
            const html = document.getElementById(id)?.innerHTML
            const transfer = document.createElement('input')
            document.body.appendChild(transfer)
            transfer.value = html as string // 这里表示想要复制的内容
            transfer.focus();
            transfer.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            transfer.blur()
            document.body.removeChild(transfer)
            this.$message.success('Copied！')

        }
        async getPayCode () {
            const res: any = await Caxios.post({ url: '/api/official/order/payConfig' , data: {
                    branchSchoolCode: this.branchschoolCode
                }})
            if (res.code !== '10000' || !res.data || res.data?.length <= 0) return
            const codeitem = res.data.find((item: any) => item.code === 'UEN')
            const imgitem = res.data.find((item: any) => item.code === 'PayNow_QR_Code')
            const AccountNumber = res.data.find((item: any) => item.code === 'OCBC_Bank_Account_Number')
            this.codeValue = codeitem.name
            this.imgsrc = imgitem.name
            this.AccountNumber = AccountNumber.name
        }
    }
